export default {
  text: '#000000', //black
  primary: '#ffffff', //white
  secondary: '#8c8c8c',
  tertiary: '#d6d6d6',
  background: '#e8e8e8',
  backgroundSecondary: 'rgba(0, 0, 0, 0.49)',
  light: '#d6d6d6',
  dark: '#504e4e'
}
