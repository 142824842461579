export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // Container
    '.container': {
      background: 'black',
      padding: ['1rem', '', '', '0.5rem 1rem'],
      display: 'flex',
      justifyContent: 'center',
      color: 'white',
      // '.react-reveal:has(.online-ordering)':{
      //   border: '1px solid red'
      // },
      '.smallNavMenu': {
        '.react-reveal': {
          display: 'flex',
          flexDirection: 'row'
        },
        // backgroundColor: '#ffffffba',
        padding: '0.5rem',
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          a: {
            color: 'white',
            fontWeight: '400',
            textTransform: 'uppercase',
            transition: 'color .2s'
          },
          'a:hover': {
            color: 'background'
          }
        },
        '.order': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      },
      color: 'black'
    },

    '.containerScrolled': {
      backgroundColor: 'text',
      color: 'text',
      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none',
      // '.react-reveal:has(.online-ordering)': {
      //   border: '1px solid red'
      // },
      '.hamburger': {
        '> div': {
          backgroundColor: 'light'
        }
      },
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          a: {
            color: 'white',
            fontWeight: '400',
            textTransform: 'uppercase',
            transition: 'color .2s'
          },
          'a:hover': {
            color: 'primary'
          }
        },
        '.order': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '10px',
      background: 'none',
      border: 'none',
      padding: '0.75rem',
      '> div': {
        backgroundColor: 'white',
        height: '2px'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    'li.navItem': {
      a: {
        color: 'white',
        fontFamily: 'body',
        // fontSize: ['1.7rem', '1.7rem', '1.7rem', '1.7rem', '1.7rem'],
        fontWeight: 'bold',
        textTransform: 'capitalize'
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'none',
        maxHeight: '50px'
      }
    },
    '.logoScrolled': {
      img: {
        filter: 'none',
        maxHeight: '50px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: ['80px', '100px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      color: '#fceed7',
      backgroundColor: 'backgroundSecondary',
      '.navMenuLogo': {
        '.image': {
          maxHeight: '150px'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'text',
    position: ['', '', 'fixed'],
    bottom: '0rem',
    zIndex: '977',
    width: '100%',
    '.logo, .image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.contactDetails-container': {
      display: 'none'
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 3.5rem', '', '0rem 3rem 0rem'],
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0rem',
      padding: '0.5rem 0.5rem 0.5rem 0rem',
      a: {
        color: 'white',
        fontSize: '0.9rem',
        margin: '0rem',
        padding: '0.5rem 0.5rem 0.5rem 0rem'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      padding: '0.25rem 1rem 0.25rem 0rem'
    },

    '.socialIconsContainer': {
      padding: '0.25rem 1rem 0.25rem 0rem',
      a: {
        margin: '0.25rem'
      }
    },
    '.copyright': {
      color: 'white',
      padding: '0.25rem 1rem 0.25rem 0rem',
      margin: '0rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '2.75rem', '3rem', '3.25rem', '3.5rem'],
    fontWeight: '300',
    letterSpacing: '1px',
    textAlign: 'left',
    marginBottom: '1.5rem'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    color: 'secondary',
    border: 'none',
    fontWeight: '400',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    fontFamily: 'body'
  },
  text: {
    // textAlign: 'center',
    lineHeight: '1.75',
    p: {
      lineHeight: '1.75',
      // textAlign: 'center',
      fontWeight: '100',
      letterSpacing: '1px',
      fontSize: 'inherit',
      color: 'inherit'
    }
  },

  sideBySide1: {
    backgroundColor: 'primary',
    // height: ['', '', '80vh'],
    '.content': {
      width: ['100%', '', '60%']
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '40%'],
      img: {
        maxHeight: '80vh'
      }
    },
    '.title': {
      variant: 'customVariants.title',

      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'left'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'left'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  centerBlock: {
    variant: 'customVariants.sideBySide1',

    '.section': {
      padding: ['2rem 1rem', '', '2rem'],
      backgroundColor: 'rgba(255,255,255,0.75)',
      margin: 'auto 0vw auto auto'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',

      '*': {}
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      // height: '70vh !important',
      width: '100%'
    },
    '.slick-slide img': {
      filter: 'brightness(0.7)'
    },
    'svg.slick-arrow': {
      backgroundColor: 'backgroundSecondary',
      height: '30px',
      width: '30px',
      color: 'white',
      padding: '0.1rem'
    },
    'svg.slick-prev': {
      left: '0.25rem'
    },
    'svg.slick-next': {
      right: '0.25rem'
    },
    '.slick-dots': {
      display: 'none !important'
    },

    '.hero_content_container': {
      boxSizing: 'border-box',
      // position: 'static',
      order: '2',
      width: '90%',
      maxWidth: 'unset',
      // transform: 'unset',
      // position: 'relative',
      // top: ['-2rem', '-2rem', '-3rem', '-4rem', '-5rem', '-6rem'],
      left: '0rem'
    },

    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      marginBottom: '0rem',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['3rem', '4rem', '5rem', '6rem'],
      color: '#e3fced',
      fontWeight: 'normal',
      marginBottom: '0rem',
      fontFamily: 'heading',
      textTransform: 'captialize',
      letterSpacing: '0px'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },

    '::before': {
      content: "'Explore'",
      position: 'absolute',
      bottom: '6rem',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: '100',
      color: 'white'
    },
    '::after': {
      content: "''",
      position: 'absolute',
      bottom: '4rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(45deg)',
      borderBottom: 'solid 3px white',
      borderRight: 'solid 3px white',
      width: '40px',
      height: '40px'
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageMenuBoxes: {
    padding: ['7.5vh 1rem', '7.5vh 2rem'],
    margin: ['', ' 0rem 0rem'],
    zIndex: '10',
    '.contentBoxes': {
      width: '100%',
      maxWidth: '1200px'
    },
    '.box': {
      backgroundColor: 'white',
      margin: ['0.5rem', '1rem', '0.5rem', '1rem'],
      // boxShadow: '2px 4px 10px black',
      padding: ['0.5rem', '', '', '0.5rem', '1rem'],
      transition: 'all ease-in-out 0.8s',
      position: 'relative',
      width: ['calc(100% - 1rem)', 'calc(100% - 2rem)', 'calc(50% - 1rem)', 'calc(25% - 2rem)'],
      '.image': {
        height: '100%',
        minHeight: '300px',
        height: '300px',
        objectFit: 'cover',
        // marginBottom: '1.5rem',
        transition: 'all ease-in-out 0.7s'
      },
      '.title': {
        variant: 'customVariants.subtitle',
        border: 'none',
        width: 'fit-content',
        position: 'absolute',
        top: 'calc(302px + 1rem)',
        backgroundColor: 'white',
        transform: 'translateY(-100%)',
        padding: '1rem 2rem 0rem 0rem',
        textTransform: 'capitalize',
        fontWeight: '300',
        fontSize: ['1rem', '', '1.2rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1rem', '', '1.2rem', '1.2rem'],
        color: 'primary',
        order: 'unset'
      },
      ':hover': {
        // backgroundColor: 'transparent',
        // color: 'white',
        '.image': {
          filter: 'grayscale(1)'
        }
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 2rem 1rem'],
    margin: ['2rem 0rem 3rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      backgroundColor: 'primary',
      fontFamily: 'body',
      color: 'black'
    },
    '.text': {
      fontFamily: 'body',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['0px', '', '', 'solid 3px black'],
      borderColor: ['', '', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['transparent', '', '', 'transparent'],
      color: ['black', '', '', 'black'],
      margin: ['', '', '', '8px']
    },
    '.date': {
      backgroundColor: 'text',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      fontSize: '0.8rem',
      color: 'white',
      fontSize: '1.1rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'primary',
    padding: ['3rem 1rem', '', '3rem 7.5vw']
  },

  homepageReservations: {
    variant: 'customVariants.sideBySide1',
    maxHeight: ['', '', '50vh'],
    backgroundColor: 'primary',
    color: 'black',
    'div.content': {
      width: ['100%', '', '40%'],
      '.subtitle': {
        color: 'white'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '60%']
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    background: 'primary',
    padding: ['3rem 1rem', '', '3rem 2rem'],
    'div.content': {
      width: ['100%', '', '65%']
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '35%']
    }
  },

  homepageEvents: {
    variant: 'customVariants.centerBlock'
  },
  homepageContactUs: {
    variant: 'customVariants.centerBlock',
    '.section': {
      backgroundColor: 'rgba(247, 247, 247, 0.8)',
      padding: '2rem 2.3rem'
    }
  },

  homepagePrivateEventsTitle: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      color: 'white!important'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      margin: 'auto',
      width: '100%'
    }
  },
  homepagePrivateEvents: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    marginBottom: '3rem',
    alignItems: ['', '', 'center'],
    overflow: 'hidden',
    'div.content': {
      width: ['100%', '', '45%'],
      backgroundColor: 'black',
      transform: ['', '', 'translateX(10vw)']
    },
    'div.lazyload-wrapper': {
      width: ['100%', '', '55%']
    },
    '.subtitle': {
      color: 'white'
    }
  },

  // ? ========================
  // ? =====  Reservations page  =====
  // ? ========================

  reservation: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['6rem 1rem', '', '10rem 1rem'],
    flexDirection: 'column',
    backgroundColor: '#f3f3f3',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menuV1: {
    p: {
      color: 'black'
    },
    '.menuItemPriceVariants': {
      color: 'dark'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    paddingTop: '10vh',
    backgroundColor: 'black',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      color: 'primary'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },
    '.eventDaysContainer span': {
      color: 'secondary'
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemTitle': {
      textTransform: 'unset',
      fontFamily: 'body'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: 'blue',
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      position: 'relative',
      '::before': {
        content: "'Reservations by phone ONLY!!'",
        position: 'absolute',
        color: 'white',
        fontSize: '1.25rem',
        top: ['-100px', '-100px', '-60px'],
        left: '0',
        right: '0',
        width: '100%',
        height: '100px',
        textAlign: 'center'
      }
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'text',
    padding: ['10rem 1rem 3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      color: 'white',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Private page  ====
  // ? ========================

  privateParties: {
    variant: 'customVariants.sideBySide1',
    marginBottom: '0rem',
    backgroundAttachment: ['', '', 'unset'],
    backgroundColor: 'white',
    backgroundImage: 'unset',

    '.title': {
      variant: 'customVariants.title',

      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      order: '2',
      width: '100%'
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3'
    },
    alignItems: ['', '', 'center'],
    overflow: 'hidden'
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
