export default {
  heading: 'Tiro Devanagari Sanskrit, serif',
  body: 'Noto Serif KR, sans-serif',
  monospace: 'Tiro Devanagari Sanskrit, monospace',
  display: 'Tiro Devanagari Sanskrit, serif',
  display2: 'Tiro Devanagari Sanskrit, sans-serif',
  display3: 'Tiro Devanagari Sanskrit',
  googleFonts: [
    'Tiro Devanagari Sanskrit:100,200,300,400,500,600,700,800,900',
    'Tiro Devanagari Sanskrit',
    'Noto Serif Display:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: [],
  customUrls: []
}
